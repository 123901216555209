<template>
    <div>
        <b-overlay
            :show="isLoading"
            rounded
            opacity="0.6"
            spinner-variant="primary"
            spinner-mediam
        >
            <transition
                :name="$store.state.appConfig.layout.routerTransition"
                mode="out-in"
            >
                <b-row>
                    <b-col md="9">
                        <b-card 
                            v-if="Object.keys(mhd_order).length > 0" 
                            :title="$t('Checkout & Pay')"
                        >
                            <b-card-body>
                                <div class="text-center">
                                    <h4 class="text-secondary">{{$t('Enter your credit card details to checkout')}}</h4>
                                    <p class="text-danger">{{$t('Card data will not be stored')}}</p>
                                    <h4 class="text-primary">{{$t('Total')}} {{mhd_order.total}} {{$t('SAR')}}</h4>
                                    <b-col sm="8" cols="12" offset-sm="2">
                                        <form id="form-container" method="post" action="/admin/mhd-order/payment/tapCharge">
                                            <!-- Tap element will be here -->
                                            <div id="element-container"></div>
                                            <div id="error-handler" role="alert"></div>
                                            <!-- <div id="success" style=" display: none;;position: relative;float: left;">
                                                    Success! Your token is <span id="token"></span>
                                            </div> -->
                                            <!-- Tap pay button -->
                                            <button id="tap-btn" class="btn btn-primary btn-block">
                                                {{$t('Pay')}}
                                                <template v-if="isLoadingSave">
                                                    <b-spinner small />
                                                </template>
                                            </button>
                                        </form>
                                    </b-col>
                                </div>
                            </b-card-body>
                        </b-card>
                    </b-col>
                </b-row>
            </transition>
        </b-overlay>
    </div>
</template>

<script>
import Ripple from 'vue-ripple-directive'
import useJwt from '@/auth/useJwt'
export default {
    directives: {
        Ripple,
    },
    mounted() {
        // get bluebird file
        let bluebird = document.createElement('script')
        bluebird.setAttribute('src', 'https://cdnjs.cloudflare.com/ajax/libs/bluebird/3.3.4/bluebird.min.js')
        document.head.appendChild(bluebird)
        // get gosell file
        let gosell = document.createElement('script')
        gosell.setAttribute('src', 'https://secure.gosell.io/js/sdk/tap.min.js')
        document.head.appendChild(gosell)

        this.getResults()
        // let vm = this
        // setTimeout(() => {
        //     vm.getTapForm()
        // }, 1500);
    },
    data() {
        return {
            mhd_order: {},
            tapChargeObject: {},
            isLoading: true,
            isLoadingSave: false
        }
    },
    methods: {
        getResults() {
            useJwt.post('/mhd-orders/get-by-id',{id: this.$route.params.mhd_order_id})
            .then((response) => {
                // console.log(response.data)
                this.mhd_order = response.data.data
                let vm = this
                this.isLoading = false
                setTimeout(() => {
                    vm.getTapForm()
                }, 1500);
            })
            .catch(response => {
                // console.log(response);
            });
        },
        getTapForm() {
            let vm = this
            //pass your public key from tap's dashboard
            var tap = window.Tapjsli(process.env.VUE_APP_TAP_PUBLIC_KEY);

            var elements = tap.elements({});

            var style = {
                base: {
                    color: '#535353',
                    lineHeight: '18px',
                    fontFamily: 'sans-serif',
                    fontSmoothing: 'antialiased',
                    fontSize: '16px',
                    '::placeholder': {
                    color: 'rgba(0, 0, 0, 0.26)',
                    fontSize:'15px'
                    }
                },
                invalid: {
                    color: 'red'
                }
            };
            // input labels/placeholders
            var labels = {
                cardNumber:"رقم البطاقة",
                expirationDate:"MM/YY",
                cvv:"الرقم السري CVV",
                cardHolder:"اسم حامل البطاقة"
            };
            //payment options
            var paymentOptions = {
                currencyCode:["SAR"],
                labels : labels,
                TextDirection:'rtl'
            }
            //create element, pass style and payment options
            var card = elements.create('card', {style: style},paymentOptions);
            //mount element
            if (document.getElementById('element-container')) {
                card.mount('#element-container');
                //card change event listener
                card.addEventListener('change', function(event) {
                    if(event.loaded){
                        console.log("UI loaded :"+event.loaded);
                        console.log("current currency is :"+card.getCurrency())
                    }
                    var displayError = document.getElementById('error-handler');
                    if (event.error) {
                        displayError.textContent = event.error.message;
                    } else {
                        displayError.textContent = '';
                    }
                });

                // Handle form submission
                var form = document.getElementById('form-container');
                form.addEventListener('submit', function(event) {
                    event.preventDefault();
                    vm.isLoadingSave = true
                    tap.createToken(card).then(function(result) {
                        // console.log(result);
                        if (result.error) {
                            // Inform the user if there was an error
                            var errorElement = document.getElementById('error-handler');
                            errorElement.textContent = result.error.message;
                            vm.isLoadingSave = false
                        } else {
                            // Send the token to your server
                            // var errorElement = document.getElementById('success');
                            // errorElement.style.display = "block";
                            // var tokenElement = document.getElementById('token');
                            // tokenElement.textContent = result.id;
                            
                            vm.tapChargeObject = {
                                mhd_order_id: vm.mhd_order.id,
                                tapToken: result.id
                            }
                            vm.tapCharge()

                        }
                    });
                });
                card.addEventListener('change', function(event) {
                    if(event.BIN){
                        console.log(event.BIN)
                    }
                    var displayError = document.getElementById('card-errors');
                    if (displayError) {
                        if (event.error) {
                            displayError.textContent = event.error.message;
                        } else {
                            displayError.textContent = '';
                        }
                    }
                });
            }
        },
        // tapTokenHandler(token) {
        //     // Insert the token ID into the form so it gets submitted to the server
        //     var form = document.getElementById('form-container');
        //     var hiddenInput = document.createElement('input');
        //     hiddenInput.setAttribute('type', 'hidden');
        //     hiddenInput.setAttribute('name', 'tapToken');
        //     hiddenInput.setAttribute('value', token.id);
        //     form.appendChild(hiddenInput);

        //     // Submit the form
        //     // form.submit();

        // },
        tapCharge() {
            useJwt.post('/mhd-orders/tapCharge',this.tapChargeObject)
            .then((response) => {
                // console.log(response.data)
                window.location.href = response.data.data
            })
            .catch(response => {
                // console.log(response);
                this.isLoadingSave = false
            });
        }
    },
}
</script>

<style lang="sass" scoped>

</style>
